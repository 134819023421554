body {
  margin-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 90px 50px;
  background-image: repeating-linear-gradient(
    90deg,
    #444,
    #444 1px,
    #111 3px,
    #111
  );
}

h1 {
  margin-bottom: 10px;
}

h3 {
  margin-top: 0px;
}

li {
  margin: 25px 0;
  font-size: 18px;
}

a,
a:visited {
  color: #037329;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.page {
  position: relative;
  background-color: #fff8e7;
  border-radius: 10px;
  max-width: 600px;
  min-height: 620px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
}

.photo img {
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: 72% 0;
  border-radius: 50%;
  filter: grayscale(5%);
  border: 2px solid #111;
}

.links-list {
  list-style: none;
  padding-inline-start: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.links-list li:first-of-type {
  margin-top: 0;
}

.btn-back {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cmetadata%3E Svg Vector Icons : http://www.onlinewebfonts.com/icon %3C/metadata%3E%3Cg%3E%3Cpath fill='%23037329' d='M943.1,451.9H176.5l254.4-254.4c18.3-18.3,18.3-48.1,0-66.4l-2.9-2.9c-18.3-18.3-48.1-18.3-66.4,0L27.7,462.2C16.9,470.8,10,484,10,498.8v4.6c0,17.8,9.9,33.2,24.4,41.2l327.2,327.2c18.3,18.3,48.1,18.3,66.4,0l2.9-2.9c18.3-18.3,18.3-48.1,0-66.4L178.7,550.3h764.3c25.9,0,46.9-21,46.9-46.9v-4.6C990,472.9,969,451.9,943.1,451.9z'/%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  height: 30px;
  width: 30px;
  top: 30px;
  left: 30px;
  padding-bottom: 5px;
}

.btn-back:hover {
  border-bottom: 2px solid #037329;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.page-content span {
  font-weight: bold;
}

.experience-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-list {
  font-size: 18px;
  margin: 40px auto;
  width: 420px;
}

.project-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 400px;
}

.project-item p {
  margin: 0;
}

span.text-italic {
  font-weight: normal;
  font-style: italic;
}